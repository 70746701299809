import React from 'react'

const Dashboard = () => {
  return (
    <div className='container dashboard'>
      <table className='table'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Student Name</th>
            <th scope='col'>Grade</th>
            <th scope='col'>Date</th>
            <th scope='col'>Time</th>
            <th scope='col'>Subject</th>
          </tr>
        </thead>
      </table>
    </div>
  );
}

export default Dashboard