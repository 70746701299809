// Modules
import { Routes, Route } from "react-router-dom";
import axios from "axios"
// components
import logo from "./images/logo/BFT_png_transparent.png";
import Navbar from "./components/Navbar";
// Pages
import Home from "./Pages/Home";
import Book from "./Pages/Book";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
// Misc
import "./App.css";

axios.defaults.baseURL ="http://localhost:3001"
axios.defaults.withCredentials= true

function App() {
  return (
    <div className='App'>
      <Navbar logo={logo} />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/book-session' element={<Book />} />
        <Route path='/admin-dashboard' element={<Dashboard />} />
        <Route path="/adminuser-login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
