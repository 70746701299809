import React from "react";
import { Link } from "react-router-dom";

// const meta = {
//   title: "Blueflamingo Tutors",
//   description: "",
//   canonical: "",
//   meta: {
//     charset: "utf-8",
//     name: {
//       keywords: "",
//     },
//   },
// };


const Home = () => {
  return (
    <>
      <section id='hero' class='d-flex align-items-center'>
        <div class='container' data-aos='zoom-out' data-aos-delay='100'>
          <div class='row'>
            <div class='col-xl-6'>
              <h1>Blue Flamingo Tutors</h1>
              <h2>The Helping hand we all need !</h2>
              <Link href='/book-session' class='btn-get-started scrollto'>
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>

      <main id='main'>
        {/* 
    <!-- ======= About Section ======= --> */}
        <section id='about' class='about '>
          <div class='container' data-aos='fade-up'>
            <div class='row no-gutters'>
              <div class='content col-xl-5 d-flex align-items-stretch'>
                <div class='content'>
                  <h3>Just a little about Us</h3>
                  <p>
                    Blue Flamingo Tutors is an effective service provider that
                    wants to reach students from all walks of life and assist
                    them on their academic journeys. Our team is vibrant, young,
                    experienced and most importantly - knowledgeable. We want
                    our students, and their families, to be satisfied with our
                    work, which is why we provide open communication channels
                    and add on services. We also view our students holistically
                    and thus, our aim to help you in every way possible.
                  </p>
                </div>
              </div>
              <div class='col-xl-7 d-flex align-items-stretch'>
                <div class='icon-boxes d-flex flex-column justify-content-center'>
                  <div class='row'>
                    <div
                      class='col-md-12 icon-box'
                      data-aos='fade-up'
                      data-aos-delay='100'
                    >
                      <i class='bx bx-receipt'></i>
                      <h4>Professional Services</h4>
                      <p>
                        You can count on us to not only meet but exceed all your
                        requests. Learn more about the services we provide
                        below, as well as our dedicated team, and let us know if
                        you’d like to learn more about a specific offering. We
                        are willing to lend you a helping hand in areas we find
                        interesting and that will benefit you. Let us join you
                        on this journey and be you helping friend!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- ======= Services Section ======= --> */}
        <section id='services' class='services section-bg '>
          <div class='container' data-aos='fade-up'>
            <div class='section-title'>
              <h2>Services</h2>
            </div>

            <div class='row'>
              <div class='col-md-6'>
                <div class='icon-box' data-aos='fade-up' data-aos-delay='100'>
                  <i class='bi bi-briefcase'></i>
                  <h4>Tutoring</h4>
                  <p>
                    We know that students are capable of achieving their best
                    with the right help.
                    <ul>
                      <li>
                        We meet with individual students to review and clarify
                        concepts learned in class and help students overcome
                        specific challenges.
                      </li>
                      <li>
                        We develop and implement individualised lesson plans for
                        our students.
                      </li>
                      <li>
                        We monitor our student’s progress and provide feedback
                        to parents.
                      </li>
                      <li>
                        We also give feedback to our students so that they know
                        how to improve in the future.
                      </li>
                      <li>We assist students in developing study skills.</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div class='col-md-6 mt-4 mt-md-0'>
                <div class='icon-box' data-aos='fade-up' data-aos-delay='200'>
                  <i class='bi bi-card-checklist'></i>
                  <h4>Study skills and Exam Preparations</h4>
                  <p>
                    The Study Skills Incremental Course covers:
                    <ul>
                      <li>How memory works</li>
                      <li>Individual student’s learning style</li>
                      <li>
                        The most effective study methods for individual student
                      </li>
                      <li>Time management</li>
                      <li>How to create a study schedule</li>
                      <li>Building confidence</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div class='col-md-6 mt-4 mt-md-0'>
                <div class='icon-box' data-aos='fade-up' data-aos-delay='300'>
                  <i class='bi bi-bar-chart'></i>
                  <h4>Reading and Scribing</h4>
                  <p>
                    We are with our students all the way right up to the
                    examination room for those who have been approved for an
                    amanuensis of an examination concession by the Department of
                    Education.
                  </p>
                </div>
              </div>
              <div class='col-md-6 mt-4 mt-md-0'>
                <div class='icon-box' data-aos='fade-up' data-aos-delay='300'>
                  <i class='bi bi-bar-chart'></i>
                  <h4>Holiday Programmes</h4>
                  <p>
                    Blue Flamingo Tutors Holiday Programmes are fun, interactive
                    sessions that are designed to make learning more fun and
                    enjoyable. They incorporate reviewing work done in class in
                    popular subjects and games that are designed to boost
                    confidence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- ======= Pricing Section ======= --> */}
        <section id='pricing' class='pricing '>
          <div class='container' data-aos='fade-up'>
            <div class='section-title'>
              <h2>Tutoring Price List</h2>
              <p>
                Special: 1 free lesson for every 8 lessons paid in advance,{" "}
                <br /> University students are also catered for! Book you
                session for only R400.
              </p>
            </div>

            <div class='row'>
              <div class='col-lg-3 col-md-6'>
                <div class='box' data-aos='fade-up' data-aos-delay='100'>
                  <h3>Grade 1-3</h3>
                  <h4>
                    <span> </span>
                  </h4>
                  <ul>
                    <li>R220.00 For one on one sessions</li>
                    <li>R180.00 For Group session</li>
                  </ul>
                </div>
              </div>

              <div class='col-lg-3 col-md-6'>
                <div class='box' data-aos='fade-up' data-aos-delay='100'>
                  <h3>Grade 4-7</h3>
                  <h4>
                    <span> </span>
                  </h4>
                  <ul>
                    <li>R260.00 For one on one sessions</li>
                    <li>R220.00 For Group session</li>
                  </ul>
                </div>
              </div>

              <div class='col-lg-3 col-md-6'>
                <div class='box' data-aos='fade-up' data-aos-delay='100'>
                  <h3>Grade 8-10</h3>
                  <h4>
                    <span> </span>
                  </h4>
                  <ul>
                    <li>R300.00 For one on one sessions</li>
                    <li>R260.00 For Group session</li>
                  </ul>
                </div>
              </div>

              <div class='col-lg-3 col-md-6'>
                <div class='box' data-aos='fade-up' data-aos-delay='100'>
                  <h3>Grade 11-12</h3>
                  <h4>
                    <span> </span>
                  </h4>
                  <ul>
                    <li>R350.00 For one on one sessions</li>
                    <li>R210.00 For Group session</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- ======= Contact Section ======= --> */}
        <section id='contact' class='contact'>
          <div class='container' data-aos='fade-up'>
            <div class='section-title'>
              <h2>Contact Us</h2>
            </div>

            <div class='row' data-aos='fade-up' data-aos-delay='100'>
              <div class='col-lg-6'>
                <div class='row'>
                  <div class='col-md-6'>
                    <div class='info-box mt-4'>
                      <i class='bx bx-envelope'></i>
                      <h3>Email Us</h3>
                      <p>admin@blueflamingotutors.co.za</p>
                    </div>
                  </div>
                  <div class='col-md-6'>
                    <div class='info-box mt-4'>
                      <i class='bx bx-phone-call'></i>
                      <h3>Call Us</h3>
                      <p>+27 71 163 4998</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class='col-lg-6'>
                <form action='/' method='post' class='php-email-form'>
                  <div class='row'>
                    <div class='col form-group'>
                      <input
                        type='text'
                        name='name'
                        class='form-control'
                        id='name'
                        placeholder='Your Name'
                        required
                      />
                    </div>
                    <div class='col form-group'>
                      <input
                        type='email'
                        class='form-control'
                        name='email'
                        id='email'
                        placeholder='Your Email'
                        required
                      />
                    </div>
                  </div>
                  <div class='form-group'>
                    <input
                      type='text'
                      class='form-control'
                      name='subject'
                      id='subject'
                      placeholder='Subject'
                      required
                    />
                  </div>
                  <div class='form-group'>
                    <textarea
                      class='form-control'
                      name='message'
                      rows='5'
                      placeholder='Message'
                      required
                    ></textarea>
                  </div>
                  <div class='my-3'>
                    <div class='loading'>Loading</div>
                    <div class='error-message'></div>
                    <div class='sent-message'>
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div class='text-center'>
                    <button type='submit'>Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer id='footer'>
        <div class='footer-top'>
          <div class='container'>
            <div class='row'>
              <div class='col-lg-6 col-md-6 footer-contact'>
                <h3>
                  BLUE FLAMINGO <span>TUTORS.</span>
                </h3>
                <p>
                  <strong>Phone:</strong> +27 71 163 4998
                  <br />
                  <strong>Email:</strong> admin@blueflamingotutors.co.za
                  <br />
                </p>
              </div>

              <div class='col-lg-6 col-md-6 footer-links'>
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i class='bx bx-chevron-right'></i> <a href='#hero'>Home</a>
                  </li>
                  <li>
                    <i class='bx bx-chevron-right'></i>{" "}
                    <a href='#about'>About us</a>
                  </li>
                  <li>
                    <i class='bx bx-chevron-right'></i>{" "}
                    <a href='#services'>Services</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class='container d-md-flex flex-column align-center justify-content-center py-4 second-footer'>
          <div class='me-md-auto text-center text-md-start'>
            <div class='copyright'>
              &copy; Copyright{" "}
              <strong>
                <span>blueflamingotutors</span>
              </strong>
              . All Rights Reserved
            </div>
            <div class='credits'>
              Designed by{" "}
              <a href='https://www.rebrandeddigital.site'>REBRANDED</a>
            </div>
          </div>
          <div class='social-links text-center text-md-end pt-3 pt-md-0'>
            <a href='instagram.com' class='instagram'>
              <i class='bx bxl-instagram'></i>
            </a>
          </div>
        </div>
      </footer>

      <a
        href='#hero'
        class='back-to-top d-flex align-items-center justify-content-center'
      >
        <i class='bi bi-arrow-up-short'></i>
      </a>
    </>
  );
};

export default Home;
