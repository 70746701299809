import React from "react";
import { Link } from "react-router-dom";

const Navbar = (props) => {
  return (
    <div>
      <header id='header' class='fixed-top d-flex align-items-center'>
        <div className='container d-flex justify-content-between align-items-center flex-setting'>
          <Link to='/' class='logo me-auto'>
            <img src={props.logo} alt='' />
          </Link>

          <nav id='navbar' class='navbar order-last order-lg-0'>
            <ul>
              <li>
                <a class='nav-link scrollto active' href='#hero'>
                  Home
                </a>
              </li>
              <li>
                <a class='nav-link scrollto' href='#about'>
                  About
                </a>
              </li>
              <li>
                <a class='nav-link scrollto' href='#services'>
                  Services
                </a>
              </li>
              <li>
                <a class='nav-link scrollto' href='#contact'>
                  Contact
                </a>
              </li>
            </ul>
            <i class='bi bi-list mobile-nav-toggle'></i>
          </nav>

          <Link to='/book-session' class='get-started-btn scrollto'>
            Book Session
          </Link>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
