import React from "react";
import {Box, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup"
import FormInput from "../components/Form/FormInput";
import Button from "@mui/material/Button";


const INITIAL_FORM_STATE = {
  email: "",
  password:""
}

const FORM_VALIDATION = Yup.object().shape({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string().required("Password is required")
});


const Login = () => {
    const handleForm =(e) => {
        e.preventDefault()

        const data = e.target()
        const formData = new FormData(data)
        const formDataObj = Object.fromEntries(formData.entries());
        const form = JSON.stringify(formDataObj);
        
    }

  return (
    <div className='login'>
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={({ values }) => {
          console.log(values);
        }}
      >
        <Form>
          <Typography variant='h6' sx={{ margin: "1rem auto" }}>
            Log In
          </Typography>

          <FormInput name='email' label='Email' sx={{ margin: ".5rem auto" }} />
          <FormInput
            name='password'
            label='Password'
            sx={{ margin: ".5rem auto" }}
          />
          <Button variant='contained' sx={{ margin: ".5rem auto" }}>
            Submit
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default Login;
