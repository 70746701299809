import { React, useState, useEffect } from "react";
import * as Yup from "yup";

import { Grade } from "../Data/Grade";
import { Session } from "../Data/Session";

import axios from "axios";

const INITIAL_FORM_STATE = {
  studentsName: "",
  grade: "",
  email: "",
  sessionType: "",
  time: "",
  date: "",
};

const FORM_VALIDATION = Yup.object().shape({
  studentsName: Yup.string().required("Students Name  is Required"),
  grade: Yup.string().required("Grade is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  sessionType: Yup.string().required("Please Seelect Session Type"),
  time: Yup.string().required(
    "Please select what time you would like the session to be "
  ),
  date: Yup.date().required("Session date is required"),
});

const Book = () => {


  const [data, setData] = useState({
    studentsName: "",
    email:"",
    grade:"",
    session:"",
    time:"",
    date:""
  })
  const handleSubmit = (e) => {
    e.prevantDefault();
    axios.get("/book");
  };

  useEffect(() => {}, []);
  return (
    <div className='book'>
      <form onSubmit={handleSubmit}>
        {/* Students Name */}
        <div class='mb-3'>
          <label for='studentsName' class='form-label'>
            Students Name
          </label>
          <input
            type='text'
            class='form-control'
            id='studentsName'
            name='studentsName'
            value={data.studentsName}
            onChange={(e) => setData({ ...data, studentsName: e.target.value })}
          />
        </div>
        {/* Email */}

        <div class='mb-3'>
          <label for='Email1' class='form-label'>
            Email address
          </label>
          <input
            type='email'
            class='form-control'
            id='Email1'
            aria-describedby='emailHelp'
            name='email'
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
        </div>

        {/* Grade */}

        <div className='mb-3'>
          <label class='visually-hidden' for='autoSizingSelect'>
            Grade
          </label>
          <select
            class='form-select'
            name='grade'
            id='autoSizingSelect'
            value={data.grade}
            onChange={(e) => setData({ ...data, grade: e.target.value })}
          ></select>
        </div>

        {/* Session */}
        <div className='mb-3'>
          <label class='visually-hidden' for='autoSizingSelect'>
            Session Type
          </label>
          <select
            class='form-select'
            name='session'
            id='autoSizingSelect'
            value={data.session}
            onChange={(e) => setData({ ...data, session: e.target.value })}
          ></select>
        </div>

        {/* Date */}
        <div class='mb-3'>
          <label for='Date1' class='form-label'>
            Session Date
          </label>
          <input
            type='date'
            class='form-control'
            id='Date1'
            name='date'
            value={data.date}
            onChange={(e) => setData({ ...data, date: e.target.value })}
          />
        </div>
        {/* Time */}

        <div class='mb-3'>
          <label for='Time1' class='form-label'>
            Session Time
          </label>
          <input
            type='time'
            class='form-control'
            id='Time1'
            name='time'
            value={data.time}
            onChange={(e) => setData({ ...data, time: e.target.value })}
          />
        </div>

        <button type='submit' class='btn btn-primary'>
          Book Session
        </button>
      </form>
    </div>
  );
};

export default Book;
